
import { LessonItemModel } from "@/models";
import { defineComponent, PropType } from "vue";
import { routeNames } from "@/router/routeNames";
import { routeHashes } from "@/router/routeHashes";
import LessonStickerVue from "./LessonSticker.vue";

const __default__ = defineComponent({
    components: {
        "lesson-sticker": LessonStickerVue
    },
    props: {
        lesson: {
            type: Object as PropType<LessonItemModel>,
            required: true
        },
        themeColorHex: {
            type: String,
            required: true
        },
        isParent: {
            type: Boolean,
            required: true
        }
    },
    emits: ["lesson-clicked"],
    setup(props, { emit }) {
        return {
            routeNames,
            routeHashes,
            imageUrl: props.lesson.thumbnailImageUrl,
            handleClick(): void {
                emit("lesson-clicked");
            }
        };
    }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "20f81719": (_ctx.themeColorHex)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__