import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e0740b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.sticker?.imageUrl)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: _normalizeClass(["img-fluid", {
            'img-sticker': _ctx.sticker.isEarned,
            'img-sticker grayscale': !_ctx.sticker.isEarned
        }]),
        src: _ctx.sticker.imageUrl,
        alt: `${_ctx.sticker.lessonName} Sticker`
      }, null, 10, _hoisted_1))
    : _createCommentVNode("", true)
}