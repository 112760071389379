
import { useStore } from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
    props: {
        lessonId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const { getters, state } = useStore();
        const assetBaseUrl = state.cdnBaseUrl + "/assets/images";
        return {
            assetBaseUrl,
            sticker: computed(() => getters.getCourseSticker(props.lessonId))
        };
    }
});
