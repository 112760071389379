
import { computed, defineComponent, ref } from "vue";

import { AppActionTypes, AppMutationTypes, useStore } from "@/store";
import { COLOR_DEFAULT, loadUserDependentData } from "@/helpers";

import LessonContent from "@/components/lesson-card/LessonContent.vue";
import PageGreetingVue from "@/components/PageGreeting.vue";
import { CourseItemModel } from "@/models";

import swal from "sweetalert";

export default defineComponent({
    components: {
        "lesson-content": LessonContent,
        "page-greeting": PageGreetingVue
    },
    props: {
        courseId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const { getters, dispatch, state } = store;

        const isParent = computed(() =>
            state.user ? state.user.role === "Parent" : false
        );

        const hasEnabledEbkBackIssues = computed(
            () => state.user?.hasEnabledEbkBackIssues
        );

        const course = computed<CourseItemModel | null>(() =>
            getters.getCourseItem(props.courseId)
        );

        const lessons = computed(() => {
            let items = getters.getLessonItems(props.courseId) || [];
            if (
                course.value?.isEverBrightKids &&
                !hasEnabledEbkBackIssues.value
            ) {
                // append the "enable ebk back issues" lesson
                const imageBaseUrl = state.cdnBaseUrl + "/assets/images";
                items = [
                    ...items,
                    {
                        id: 0,
                        courseId: props.courseId,
                        name: getters.isStudent
                            ? "You now have access to your very own library of exciting EverBright Kids issues! Ask your parent or guardian to unlock!"
                            : "Your child now has access to their very own library of exciting EverBright Kids issues! Just click here to unlock!",
                        thumbnailImageUrl: getters.isStudent
                            ? `${imageBaseUrl}/UNLOCK-EBK-kids.png`
                            : `${imageBaseUrl}/UNLOCK-EBK-parents.png`
                    }
                ];
            }
            return items;
        });
        const themeColorHex = ref<string>(COLOR_DEFAULT);

        // Page greeting
        const title = ref<string>("");
        const message = "Earn stickers and level up by completing activities!";

        const handleEnableEbkBackIssues = async (id: number): Promise<void> => {
            // this only applies to the enable back issues "lesson"
            if (id !== 0 || getters.isStudent) {
                return;
            }

            const result = await dispatch(AppActionTypes.enableEbkBackIssues);

            if (result.status !== 200) {
                return;
            }

            // Reload lessons
            await dispatch(AppActionTypes.getLessonItems, props.courseId);

            // Show Success Message
            await swal({
                text: "Past issues have been unlocked!",
                icon: "success"
            });
        };

        loadUserDependentData(store, async () => {
            if (!lessons.value || lessons.value.length === 0) {
                await dispatch(AppActionTypes.getLessonItems, props.courseId);

                if (!lessons.value) {
                    return;
                }
            }

            if (!isParent.value) {
                dispatch(AppActionTypes.getCourseStickers, props.courseId);
            }

            if (!course.value) {
                await dispatch(AppActionTypes.getCourseItems);

                if (!course.value) {
                    return;
                }
            }

            if (course.value.courseColorHex) {
                themeColorHex.value = course.value?.courseColorHex;
            }

            if (course.value?.name) {
                title.value = `${course.value.name} 🗺️`;
                store.commit(AppMutationTypes.setPageTitle, course.value.name);
            }

            if (course.value?.logoImageUrl) {
                store.commit(
                    AppMutationTypes.setPageImage,
                    course.value.logoImageUrl
                );
            }
        });

        return {
            lessons,
            loading: computed(() => getters.loading),
            themeColorHex,
            isStudent: computed(() => getters.isStudent),
            isParent,
            title,
            message,
            course,
            hasEnabledEbkBackIssues,
            handleEnableEbkBackIssues,
            imageBaseUrl: state.cdnBaseUrl + "/assets/images"
        };
    }
});
