import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66e95d96"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row justify-content-center"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_page_greeting = _resolveComponent("page-greeting")!
  const _component_lesson_content = _resolveComponent("lesson-content")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_loading_indicator, { class: "col" })
      ]))
    : (_ctx.lessons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_page_greeting, {
            message: !_ctx.isParent ? _ctx.message : '',
            title: _ctx.title
          }, null, 8, ["message", "title"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lessons, (lesson) => {
            return (_openBlock(), _createElementBlock("div", {
              key: lesson.id,
              class: "col-xl-4 col-md-6 pb-3"
            }, [
              _createVNode(_component_content_card, {
                "border-color-hex": _ctx.themeColorHex,
                class: "h-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_lesson_content, {
                    lesson: lesson,
                    "theme-color-hex": _ctx.themeColorHex,
                    "is-parent": _ctx.isParent,
                    onLessonClicked: ($event: any) => (_ctx.handleEnableEbkBackIssues(lesson.id || 0))
                  }, null, 8, ["lesson", "theme-color-hex", "is-parent", "onLessonClicked"])
                ]),
                _: 2
              }, 1032, ["border-color-hex"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
}